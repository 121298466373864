import React, { useState } from "react"; //useState
//import * as Sentry from "@sentry/browser";
import BaggingReportFields from "./bagging-report-fields";
import Spinner from "../spinner";
import { useAuth0 } from "../../auth0_spa";
//import { fetchBaggingReport } from "../../utils/api";
//import { Link } from "react-router-dom";
import Breadcrumb from "../breadcrumb";

const BaggingReport = (props) => {
  let { crop } = props.match.params; //lineItem
  let { state = {} } = props.location;
  let [baggingReport] = useState(state);
  //let [isFetching, setFetching] = useState(false);
  let {
    loading,
    isAuthenticated,
    loginWithRedirect,
    //getTokenSilently,
  } = useAuth0();

  /*useEffect(() => {
    const getBaggingReport = async () => {
      let token = await getTokenSilently();

      try {
        let res = await fetchBaggingReport({
          token,
          id: lineItem,
          crop
        });
        setBaggingReport(res.data);
        setFetching(false);
      } catch (e) {
        setFetching(false);
        alert(e.message);
        console.error(e.message);
        Sentry.captureException(e);
      }
    };

    if (!loading) {
      setFetching(true);
      getBaggingReport();
    }
  }, [loading, getTokenSilently, props]);*/

  if (loading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return (
    <div>
      <Breadcrumb capitalize rootLinkName="Crops" />
      <div className="box is-relative min-h-250">
        <h1 className="title has-text-primary has-text-weight-bold">
          {baggingReport.form_id || ""}
        </h1>
        {/*{isFetching ? (
          <Spinner />
        ) : (*/}
          <BaggingReportFields baggingReport={baggingReport} crop={crop} />
        {/*)}*/}
      </div>
    </div>
  );
};

export default BaggingReport;
