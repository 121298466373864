import React from 'react';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Spinner = ({className = 'has-text-grey-light'}) => (
      <div className={`is-overlay absolute-center fill-screen-fixed ${className}`}>
        <FontAwesomeIcon icon={faCog}
                         size={'7x'}
                         spin />
      </div>
    );

export default Spinner;
