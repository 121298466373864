import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useAuth0 } from '../../auth0_spa';
import { groupBaggingReportsById, getBaggingReportLocations, getBaggingReportDates, getBaggingReportRelativeMaturity } from '../../utils/array';
import Spinner from '../spinner';
import BaggingReportsTable from '../bagging-reports-table';
import BaggingReportsSuppliesTable from '../bagging-reports-supplies-table';
import BaggingReportsBulkTable from '../bagging-reports-bulk-table';
//import { Link } from 'react-router-dom';
import BaggingReportSummary from '../bagging-report-summary';
//import { genFakeData } from '../../utils/fakeData';
import { fetchBaggingReports } from '../../utils/api';
import Breadcrumb from "../breadcrumb";

const BaggingReportsTables = (props) => {
  let { loading, getTokenSilently } = useAuth0();
  let { state = {} } = props.location;
  let { reports = [] } = state;
  let tablesFromState = groupBaggingReportsById(reports);
  let contractNumber = props.match.params.id;
  let crop = props.match.params.crop;
  let [cropYear, setCropYear] = useState(reports[0]?.crop_year);
  let [isFetching, setFetching] = useState(false);
  let [tables, setTables] = useState(tablesFromState);
  let [locations, setLocations] = useState(getBaggingReportLocations(reports, crop));
  let [dateString, setDateString] = useState(getBaggingReportDates(reports, crop));
  let [relativeMaturity, setRelativeMaturity] = useState(getBaggingReportRelativeMaturity(reports, crop));

  useEffect(() => {
    const callApi = async () => {
      let token = await getTokenSilently();

      try {
        let res = await fetchBaggingReports({token, crop});
        let allTables = res.data.filter(report => report.report_id === contractNumber);
        let cropYear = res.data[0]?.crop_year;
        let subTables = groupBaggingReportsById(allTables);

        setCropYear(cropYear);
        setTables(subTables);
        setLocations(getBaggingReportLocations(allTables, crop));
        setDateString(getBaggingReportDates(allTables, crop));
        setRelativeMaturity(getBaggingReportRelativeMaturity(allTables, crop));
        setFetching(false);
      } catch(e) {
        setFetching(false);
        alert(e.message);
        console.error(e.message);
        Sentry.captureException(e);
      }
    };

    if (!loading && !props.location.state) {
      setFetching(true);
      callApi();
    }
  }, [loading, getTokenSilently, contractNumber, props.location.state, crop]);

  if (loading || isFetching) {
    return <Spinner />;
  }

  return (
    <div className="is-relative">
      <Breadcrumb capitalize rootLinkName="Crops" />

      <BaggingReportSummary contractNumber={contractNumber}
                            tables={tables}
                            dateString={dateString}
                            locations={locations}
                            cropYear={cropYear}
                            relativeMaturity={relativeMaturity}
      />

      <div className="panel has-background-white is-primary">
        <div className="panel-heading has-text-centered">Seed Related</div>
        <div className="panel-block is-block">
          {
            tables.map((table, i) => {
              return (
                <div key={`bagging-reports-table-${i}`} className="max-w-100">
                  <BaggingReportsTable match={props.match}
                                       reportId={table.reportId}
                                       reports={
                                         table.reports.filter(report => {
                                           let {batch_type, species, crop} = report
                                           let reportTypes = [batch_type, species, crop];
                                           return !reportTypes.includes('Supplies') &&
                                             !reportTypes.includes('Bulk');
                                         })
                                      }
                  />
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="panel has-background-white is-primary">
        <div className="panel-heading has-text-centered">Supplies</div>
        <div className="panel-block is-block">
          {
            tables.map((table,i) => {
              return (
                <div key={`bagging-reports-sup-table-${i}`}>
                  <BaggingReportsSuppliesTable
                    reports={table.reports.filter(report => {
                      let { batch_type, species, crop } = report
                      return [batch_type, species, crop].includes('Supplies');
                    })}
                  />
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="panel has-background-white is-primary">
        <div className="panel-heading has-text-centered">Bulk</div>
        <div className="panel-block is-block">
          {
            tables.map((table,i) => {
              return (
                <div key={`bagging-reports-bulk-table-${i}`}>
                  <BaggingReportsBulkTable
                    reports={table.reports.filter(report => {
                      let { batch_type, species, crop } = report
                      return [batch_type, species, crop].includes('Bulk');
                    })}
                    crop={crop}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default BaggingReportsTables;
