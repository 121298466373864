import React, { useState, useEffect } from 'react';
import { DataTable } from '@seedbin/seedbin-ui';

const BaggingReportsSuppliesTable = ({reports}) => {
  let [ columns, setColumns ] = useState([]);

  useEffect(() => {
    let hasBrandAlias = reports.every(report => report.brand_alias);

    setColumns([
      {colName: hasBrandAlias ? 'brand_alias' : 'batch_id', title: 'Name'},
      {colName: 'number_of_packages', title: 'Number'},
    ])
  }, [reports]);

  if (!reports.length) {
    return <h4 className="title is-4">No Supplies Found</h4>
  }

  return (
    <div className="is-relative">
      <DataTable tblData={reports}
                 headers={columns}
                 types={{}} />
    </div>
  );
};

export default BaggingReportsSuppliesTable;