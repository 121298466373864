import React from "react";
import baggingReportsTableSettings from "../../utils/baggingReportsTableSettings.js";

const BaggingReportFields = ({baggingReport, crop}) => {
  return (
    <div className="columns is-multiline">
      {getReportKeys().map((field, i) => {
        let val = getReportValue(field);
        let label = getFieldLabel(field);

        return (
          <div key={`report-detail-${i}`} className="column is-3">
            <div className="field">
              <label className="label is-small capitalize">{label}</label>
              <p className="control">
                {/*placeholder={label} */}
                <input
                  className="input is-success"
                  disabled
                  type="text"
                  value={val || ""}
                />
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );

  function getReportKeys() {
    let exclude = [
      "id",
      "bagging_report_id",
      "related_record",
      "related_production_update",
      "activity",
      "display",
      "migratedQB",
    ];
    return Object.keys(baggingReport).filter(
      (key) => !exclude.includes(key)
    );
  }

  function getReportValue(field) {
    let { formats } = baggingReportsTableSettings[crop];
    let formatter = formats[field];

    return formatter ? formatter(baggingReport[field]) : baggingReport[field];
  }

  function getFieldLabel(field) {
    let { labels } = baggingReportsTableSettings[crop];

    return labels[field] || field.replace(/(_)/g, ' ');
  }
}

export default BaggingReportFields;
