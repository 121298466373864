import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchInput = ({ label, onChange, inputBg = 'has-background-white-ter' }) => {
  let handleChange = (e) => {
    e.persist();
    onChange(e);
  };

  return (
    <div className="field search-input">
      {label ? <label className="label"> {label} </label> : ""}
      <p className="control has-icons-left">
        <input
          className={`input is-success ${inputBg} has-text-black`}
          onChange={handleChange}
          type="search"
          placeholder="Search"
        />
        <span className="icon is-small is-left has-text-grey">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </p>
    </div>
  );
};

export default SearchInput;
