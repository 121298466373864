import React  from 'react';
import baggingReportsTableSettings from '../../utils/baggingReportsTableSettings';
import { DataTable } from '@seedbin/seedbin-ui';

const BaggingReportsBulkTable = ({reports, crop}) => {
  let { headers, formats, types } = baggingReportsTableSettings[crop];

  if (!reports.length) {
    return <h4 className="title is-4">No Bulk Found</h4>
  }

  return (
    <div className="is-relative">
      <DataTable tblData={reports} headers={headers} types={types} formats={formats} />
    </div>
  );
};

export default BaggingReportsBulkTable;