import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const DatePicker = ({ label, onChange, id, value }) => {
  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker disableToolbar
                            autoOk={true} /*will auto close the datepicker on date select*/
                            label={label}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id={id}
                            value={value}
                            onChange={onChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }} style={{
                              marginTop: 0
                            }} />
      </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
