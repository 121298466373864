import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 *
 * @param className {string}
 * @param rootLinkName {string}
 * @param capitalize {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumb = ({className = '', rootLinkName = 'Root', capitalize = true, children}) => {
    let location = useLocation();
    let [links, setLinks] = useState([]);

    useEffect(() => {
        setLinks(location.pathname.split('/').map(dir => dir === 'undefined' ? 'All' : dir));
    }, [location]);

    return (
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <nav className={`breadcrumb ${className}`}>
              <ul>
                {links.map((link, i, ary) => {
                  // first el
                  if (i === 0) {
                    return <li key={`${location.pathname}-${i}`}><Link to={'/'}>{rootLinkName}</Link></li>;
                  }

                  // last el
                  if (i === ary.length - 1) {
                    return <li key={`${location.pathname}-${i}`} className={`is-active ${capitalize ? 'capitalize' : ''}`}><a href="/#">{link}</a></li>;
                  }

                  // middle els
                  return (
                    <li key={`${location.pathname}-${i}`}>
                      <Link className={capitalize ? 'capitalize' : ''}
                            to={`/${ary.slice(0, i+1).filter(link => link !== 'All' && link).join('/')}`}>
                        {link}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        {
          children &&
          <div className="level-right">
            <div className="level-item">
              {children}
            </div>
          </div>
        }
      </div>

    );
};

export default Breadcrumb;