export const groupBaggingReportsById = (reports) => {
  return reports.reduce((p,c) => {
    if (!p.includes(c.report_id))
      p.push(c.report_id);
    return p;
  }, [])
    .map(reportId => {
      return {
        reportId,
        reports: reports.filter(report => report.report_id === reportId)
      };
    });
};

export const getBaggingReportLocations = (reports, crop) => {
  let locationKeys = {
    corn: 'report_location_code',
    soybeans: 'report_location_code'
  };

  return reports.reduce((p,c) => {
    if (!p.includes(c[locationKeys[crop]])) p.push(c[locationKeys[crop]]);
    return p;
  }, []);
};

export const getBaggingReportVarieties = (reports) => {
  return reports.reduce((p,c) => {
    if (!p.includes(c.brand_alias) && c.batch_type !== 'Supplies') p.push(c.brand_alias);
    return p;
  }, []);
};

export const getBaggingReportDates = (reports, crop) => {
  let dateKeys = {
    corn: 'report_date',
    soybeans: 'report_date'
  };
  let dates = reports
    .map(report => report[dateKeys[crop]])
    .sort((a,b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
  let startDate = new Date(dates[0]).toLocaleDateString();
  let endDate = new Date(dates[dates.length - 1]).toLocaleDateString();
  return startDate === endDate ? startDate : startDate + ' - ' + endDate;
};

export const getBaggingReportRelativeMaturity = (reports, crop) => {
  if (crop === 'soybeans') return '';

  return reports.filter(report => report.relative_maturity)[0]?.relative_maturity || '';
}

export default groupBaggingReportsById;
