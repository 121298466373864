import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useAuth0 } from "./auth0_spa";
import Spinner from "./components/spinner";
import {
  Layout,
  Nav,
  Sidebar,
  SidebarItems,
  ToastContainer
} from "@seedbin/seedbin-ui";
import Contracts from './components/contracts';
import Crops from './components/crops';
import ScrollToTop from "./components/scroll-to-top";
import BaggingReport from "./components/bagging-report";
import { HashRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import BaggingReportsTables from "./components/bagging-reports-tables";
import { fetchUserCompaniesList } from "./utils/api";

function App() {
  let [sidebarState, setSidebarState] = useState(false);
  let {
    isAuthenticated,
    loading,
    loginWithRedirect,
    user,
    logout,
    getTokenSilently
  } = useAuth0();
  //let [fetching, setFetching] = useState(false);
  let [reports, setReports] = useState([]);
  let [crop, setCrop] = useState('corn');

  useEffect(() => {
    if (user) {
      Sentry.configureScope(function(scope) {
        scope.setUser({
          email: user.email,
        });
      });
    }
  }, [user]);

  useEffect(() => {
    const getData = async () => {
      let token = await getTokenSilently();
    
      // make api call w/token
      let res = await fetchUserCompaniesList({token});

      setReports(res.data);
    };

    if (!loading) {
      //setFetching(true);
      getData()
          //.then(() => setFetching(false));
    }
  }, [loading, getTokenSilently]);

  let toggleSidebar = () => {
    setSidebarState(freshSidebarState => !freshSidebarState);
  }

  let handleClick = (crop) => {
    setSidebarState(false);
    setCrop(crop);
  }

  if (loading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  if (!user) {
    return <Spinner />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Layout isFixed
              hasPopOverSidebar
              isOpen={sidebarState}
              onClose={toggleSidebar}
              className="max-height-100vh"
              sidebarColor="has-background-black-ter z-index-25"
              sidebar={
                <Sidebar>
                  <SidebarItems>
                    <li>
                      <NavLink to="/" onClick={() => setSidebarState(false)} activeClassName={"is-active"}>
                        Bagging Reports
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink onClick={() => handleClick('corn')} to="/corn" activeClassName="is-active">Corn</NavLink>
                        </li>
                        <li>
                          <NavLink onClick={() => handleClick('soybeans')} to="/soybeans" activeClassName="is-active">Soybeans</NavLink>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <NavLink to="/" onClick={() => setSidebarState(false)} activeClassName={"is-active"}>
                        Companies
                      </NavLink>
                      <ul>
                        <li
                          key='all'
                        >
                          <NavLink  
                            onClick={() => setSidebarState(false) } 
                            to={'/' + crop}
                            // activeClassName="is-active"
                          >
                            All Companies
                          </NavLink>
                        </li>
                        {reports.map((report, i) => (
                          <li
                            key={report.company_id}
                          >
                            <NavLink 
                              onClick={() => setSidebarState(false) } 
                              to={{
                                pathname:`/${crop}/${report.company_id}`,
                                state: {title: report.company_id}  
                              }}
                              activeClassName="is-active"
                            >
                              {report.company_name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </SidebarItems>
                </Sidebar>
              }
              navbar={
                <Nav
                  logo={"/img/header-logo-desktop.svg"}
                  userName={user.given_name}
                  userProfile={user.picture}
                  navColor="remington-header-bg"
                  logout={() => logout({returnTo: window.location.origin})}>
                  <NavLink
                    className={"navbar-item"}
                    to="/"
                    activeClassName={"is-active"}>
                    Bagging Reports
                  </NavLink>
                </Nav>
              }>
        <section className={"section is-relative min-h-100 max-width-100vw"}>
          <Switch>
            <Route path="/" exact component={Crops} />
            <Route path="/:crop" exact component={Contracts} />
            <Route path="/:crop/:company" exact component={Contracts} />
            <Route path="/:crop/:company/:id" exact component={BaggingReportsTables} />
            <Route path="/:crop/:company/:id/:lineItem" exact component={BaggingReport} />
          </Switch>

          <ToastContainer position="bottom-right" autoClose={2000} />

        </section>
      </Layout>
    </Router>
  );
}

export default App;
