import React, { useState } from 'react';
import { DataTable } from '@seedbin/seedbin-ui';
import baggingReportsTableSettings from '../../utils/baggingReportsTableSettings';
import {Redirect} from 'react-router-dom';

const BaggingReportsTable = ({reports, editReport, match}) => {
  let [rowToView, setRowToView] = useState(null);
  let { params = {} } = match;
  let { headers, formats, types } = baggingReportsTableSettings[params.crop];

  const tdClicked = (data) => {
    console.log({data});
    setRowToView(data.row);
  };

  if (rowToView) {
    return <Redirect to={{
      pathname: `/${params.crop}/${params.company}/${params.id}/line-item`,
      state: rowToView
    }} />
  }

  return (
    <div className={'is-relative'}>
      {!!rowToView && <div className="box">
        <pre><code>
          {JSON.stringify(rowToView, null, 2)}
        </code></pre>
      </div>}
      <DataTable headers={headers}
                 types={types}
                 formats={formats}
                 tblData={reports}
                 onSaveEdit={editReport}
                 onTdClick={tdClicked} />
    </div>
  );
};

export default BaggingReportsTable;
