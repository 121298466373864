const baggingReportsTableSettings = {
  corn: {
    headers: [
      {
        colName: "brand_alias",
        title: "Brand",
        immutable: true
      },
      {
        colName: "package_type",
        title: "Package",
        immutable: true
      },
      {
        colName: "ssu_quantity",
        title: "SSU Qty",
      },
      {
        colName: "batch_id",
        title: "Batch ID",
        immutable: true
      },
      {
        colName: "customer_seed_size",
        title: "Seed Size",
        immutable: true
      },
      {
        colName: "package_weight_lb",
        title: "Bag Weight",
        immutable: true
      },
      {
        colName: "treatment_description",
        title: "Treatment",
        immutable: true
      }
    ],
    formats: {
      bagging_date: function(dateString) {
        return new Date(dateString).toLocaleDateString();
      }
    },
    labels: {
      "Biological_Exp_Date" : "Bio Exp Date",
      "Package" : "Package",
      "Plate_Size" : "Plate Size",
      "Major_Product_Code" : "Major Prod Code",
      "CustID" : "Cust ID",
      "variety" : "Variety",
      "DateCreated" : "Date Created",
      "ssu_quantity" : "SSU Qty",
      "Refuge_Biological_Batch__" : "Refuge BIO Batch #",
      "Major_Trait" : "Major Trait",
      "CompanyGroupCustomerPlan" : "Co Group Cust Plan",
      "SSU_Conversion" : "SSU Conversion",
      "Major_Pct" : "Major Pct",
      "Refuge_State_Grown" : "Refuge State Grown",
      "Refuge_Product_Code" : "Refuge Product Code",
      "Brand_RM" : "Brand RM",
      "Treatment" : "Treatment",
      "Refuge_Trait" : "Refuge Trait",
      "DateModified" : "Date Modified",
      "Crop_Year" : "Crop Year",
      "Bag_Weight" : "Bag Weight",
      "CompanyGroupDefaultFinalCust" : "Company Group Customer",
      "Refuge_Batch_Number" : "Refuge Batch #",
      "Tag_Code" : "Tag Code",
      "Major_State_Grown" : "Major State Grown",
      "Customer_Reference_Number" : "Customer Ref #",
      "location" : "Location",
      "Activity" : "Activity",
      "Refuge_Test_Date" : "Refuge Test Date",
      "KPerLb" : "K/lb",
      "CompanyGroupInventoryBatch" : "Company Group Inv Batch",
      "lot" : "Lot",
      "crop" : "Crop",
      "Seed_Size" : "Seed Size",
      "bagging_date" : "Bagging Date",
      "Product_Variety" : "Prod Variety",
      "Refuge_Treatment_Description" : "Refuge Test Desc.",
      "Colorant" : "Colorant",
      "Refuge_Tag_Code" : "Refuge Tag Code",
      "Label_Germ" : "Label Germ",
      "sku_id" : "SKU ID",
      "CompanyGroupInventoryBatchSupply" : "Company Group Inv Batch Sup.",
      "Refuge_Kperpound" : "Refuge K/lb",
      "Screen_Size" : "Screen Size",
      "SSU_Bag_Weight" : "SSU Bag Weight",
      "Size" : "Size",
      "Finished_Type" : "Finished Type",
      "Refuge_Colorant" : "Refuge Colorant",
      "Kperunit" : "K/unit",
      "UpdateDateTime" : "Updated",
      "order_id" : "Order ID",
      "rid" : "ID",
      "Application_ID" : "App ID",
      "Contract__" : "Contract #",
      "Major_Test_Date" : "Major Test Date",
      "Refuge_Biological_Exp_Date" : "Refuge BIO Exp Date",
      "Bag_Lip_Code" : "Bag Lip Code",
      "Biological_Batch__" : "BIO Batch #",
      "packages" : "Packages",
      "Work_Order_Notes" : "WO Notes",
      "Kilograms" : "Kilos",
      "package_type" : "Package",
      "form_id" : "Form ID"
    },
    types: {
      "Biological_Exp_Date" : Date,
      "Package" : String,
      "Plate_Size" : String,
      "Major_Product_Code" : String,
      "CustID" : String,
      "variety" : String,
      "DateCreated" : Date,
      "ssu_quantity" : Number,
      "Refuge_Biological_Batch__" : String,
      "Major_Trait" : String,
      "CompanyGroupCustomerPlan" : String,
      "SSU_Conversion" : Number,
      "Major_Pct" : String,
      "Refuge_State_Grown" : String,
      "Refuge_Product_Code" : String,
      "Brand_RM" : String,
      "Treatment" : String,
      "Refuge_Trait" : String,
      "DateModified" : Date,
      "Crop_Year" : String,
      "Bag_Weight" : Number,
      "CompanyGroupDefaultFinalCust" : String,
      "Refuge_Batch_Number" : String,
      "Tag_Code" : String,
      "Major_State_Grown" : String,
      "Customer_Reference_Number" : String,
      "location" : String,
      "Activity" : String,
      "Refuge_Test_Date" : String,
      "KPerLb" : Number,
      "CompanyGroupInventoryBatch" : String,
      "lot" : String,
      "crop" : String,
      "Seed_Size" : String,
      "bagging_date" : Date,
      "Product_Variety" : String,
      "Refuge_Treatment_Description" : String,
      "Colorant" : String,
      "Refuge_Tag_Code" : String,
      "Label_Germ" : String,
      "sku_id" : Number,
      "CompanyGroupInventoryBatchSupply" : String,
      "Refuge_Kperpound" : Number,
      "Screen_Size" : String,
      "SSU_Bag_Weight" : Number,
      "Size" : String,
      "Finished_Type" : String,
      "Refuge_Colorant" : String,
      "Kperunit" : Number,
      "UpdateDateTime" : Date,
      "order_id" : Number,
      "rid" : Number,
      "Application_ID" : String,
      "Contract__" : String,
      "Major_Test_Date" : String,
      "Refuge_Biological_Exp_Date" : String,
      "Bag_Lip_Code" : String,
      "Biological_Batch__" : String,
      "packages" : Number,
      "Work_Order_Notes" : String,
      "Kilograms" : Number,
      "package_type" : String,
      "form_id" : String
    },
  },
  soybeans: {
    headers: [
      {
        colName: "brand_alias",
        title: "Brand",
        immutable: true
      },
      {
        colName: "batch_id",
        title: "Batch ID",
        immutable: true
      },
      {
        colName: "ssu_quantity",
        title: "SSU Qty",
        immutable: true
      },
      {
        colName: "package_weight_lbs",
        title: "Bag Weight",
        immutable: true
      },
      {
        colName: "treatment_description",
        title: "Treatment",
        immutable: true
      },
      /*{
        colName: "size",
        title: "Size",
        immutable: true
      },*/
      {
        colName: "package_description",
        title: "Package",
        immutable: true
      },
    ],
    formats: {
      bagging_date: function(dateString) {
        return new Date(dateString).toLocaleDateString();
      }
    },
    labels: {
      "Biological_Exp_Date" : "Bio Exp Date",
      "Package" : "Package",
      "Plate_Size" : "Plate Size",
      "Major_Product_Code" : "Major Prod Code",
      "CustID" : "Cust ID",
      "variety" : "Variety",
      "DateCreated" : "Date Created",
      "ssu_quantity" : "SSU Qty",
      "Refuge_Biological_Batch__" : "Refuge BIO Batch #",
      "Major_Trait" : "Major Trait",
      "CompanyGroupCustomerPlan" : "Co Group Cust Plan",
      "SSU_Conversion" : "SSU Conversion",
      "Major_Pct" : "Major Pct",
      "Refuge_State_Grown" : "Refuge State Grown",
      "Refuge_Product_Code" : "Refuge Product Code",
      "Brand_RM" : "Brand RM",
      "Treatment" : "Treatment",
      "Refuge_Trait" : "Refuge Trait",
      "DateModified" : "Date Modified",
      "Crop_Year" : "Crop Year",
      "Bag_Weight" : "Bag Weight",
      "CompanyGroupDefaultFinalCust" : "Company Group Customer",
      "Refuge_Batch_Number" : "Refuge Batch #",
      "Tag_Code" : "Tag Code",
      "Major_State_Grown" : "Major State Grown",
      "Customer_Reference_Number" : "Customer Ref #",
      "location" : "Location",
      "Activity" : "Activity",
      "Refuge_Test_Date" : "Refuge Test Date",
      "KPerLb" : "K/lb",
      "CompanyGroupInventoryBatch" : "Company Group Inv Batch",
      "lot" : "Lot",
      "crop" : "Crop",
      "Seed_Size" : "Seed Size",
      "bagging_date" : "Bagging Date",
      "Product_Variety" : "Prod Variety",
      "Refuge_Treatment_Description" : "Refuge Test Desc.",
      "Colorant" : "Colorant",
      "Refuge_Tag_Code" : "Refuge Tag Code",
      "Label_Germ" : "Label Germ",
      "sku_id" : "SKU ID",
      "CompanyGroupInventoryBatchSupply" : "Company Group Inv Batch Sup.",
      "Refuge_Kperpound" : "Refuge K/lb",
      "Screen_Size" : "Screen Size",
      "SSU_Bag_Weight" : "SSU Bag Weight",
      "Size" : "Size",
      "Finished_Type" : "Finished Type",
      "Refuge_Colorant" : "Refuge Colorant",
      "Kperunit" : "K/unit",
      "UpdateDateTime" : "Updated",
      "order_id" : "Order ID",
      "rid" : "ID",
      "Application_ID" : "App ID",
      "Contract__" : "Contract #",
      "Major_Test_Date" : "Major Test Date",
      "Refuge_Biological_Exp_Date" : "Refuge BIO Exp Date",
      "Bag_Lip_Code" : "Bag Lip Code",
      "Biological_Batch__" : "BIO Batch #",
      "packages" : "Packages",
      "Work_Order_Notes" : "WO Notes",
      "Kilograms" : "Kilos",
      "package_type" : "Package",
      "form_id" : "Form ID"
    },
    types: {
      "Biological_Exp_Date" : Date,
      "Package" : String,
      "Plate_Size" : String,
      "Major_Product_Code" : String,
      "CustID" : String,
      "variety" : String,
      "DateCreated" : Date,
      "ssu_quantity" : Number,
      "Refuge_Biological_Batch__" : String,
      "Major_Trait" : String,
      "CompanyGroupCustomerPlan" : String,
      "SSU_Conversion" : Number,
      "Major_Pct" : String,
      "Refuge_State_Grown" : String,
      "Refuge_Product_Code" : String,
      "Brand_RM" : String,
      "Treatment" : String,
      "Refuge_Trait" : String,
      "DateModified" : Date,
      "Crop_Year" : String,
      "Bag_Weight" : Number,
      "CompanyGroupDefaultFinalCust" : String,
      "Refuge_Batch_Number" : String,
      "Tag_Code" : String,
      "Major_State_Grown" : String,
      "Customer_Reference_Number" : String,
      "location" : String,
      "Activity" : String,
      "Refuge_Test_Date" : String,
      "KPerLb" : Number,
      "CompanyGroupInventoryBatch" : String,
      "lot" : String,
      "crop" : String,
      "Seed_Size" : String,
      "bagging_date" : Date,
      "Product_Variety" : String,
      "Refuge_Treatment_Description" : String,
      "Colorant" : String,
      "Refuge_Tag_Code" : String,
      "Label_Germ" : String,
      "sku_id" : Number,
      "CompanyGroupInventoryBatchSupply" : String,
      "Refuge_Kperpound" : Number,
      "Screen_Size" : String,
      "SSU_Bag_Weight" : Number,
      "Size" : String,
      "Finished_Type" : String,
      "Refuge_Colorant" : String,
      "Kperunit" : Number,
      "UpdateDateTime" : Date,
      "order_id" : Number,
      "rid" : Number,
      "Application_ID" : String,
      "Contract__" : String,
      "Major_Test_Date" : String,
      "Refuge_Biological_Exp_Date" : String,
      "Bag_Lip_Code" : String,
      "Biological_Batch__" : String,
      "packages" : Number,
      "Work_Order_Notes" : String,
      "Kilograms" : Number,
      "package_type" : String,
      "form_id" : String
    },
  }
};

export default baggingReportsTableSettings;
