import axios from "axios";
import {
  urlBase
} from "../env.config";
//import fakeData from "./fakeData";

export const fetchBaggingReports = async ({token, startDate, endDate,processYear, reportType, crop, company}) => {

  if (crop === 'corn' && (reportType === 'production' || reportType === 'custom bag')) {
    reportType = '';
  }
  if (crop === 'soybeans' && (reportType === 'bagging' || reportType === 'overtreat' || reportType === 'blend')) {
    reportType = '';
  }

  return await axios.get(`${urlBase}/api/baggingreports/${crop}/`, {
    params: {
      startdate: startDate,
      enddate: endDate,
      processyear: processYear === '' ? null : processYear,
      reporttype: reportType,
      customerid: company,
      format: 'json',
    },
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchBaggingReport = async ({token, id, crop}) => {
  if (!id) {
    return Promise.resolve({data: {}});
  }

  return await axios.get(`${urlBase}/api/baggingreports/${crop}/${id}`, {
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUserCompaniesList = async ({token}) => {
  // if (!id) {
  //   return Promise.resolve({data: {}});
  // }

  return await axios.get(`${urlBase}/api/companies`, {
    params: {
      format: "json",
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editBaggingReport = async (config) => {
  let {
    token,
    recordId,
    // fromFake,
    edits
  } = config;

  /*if (fromFake) {
    let fakeData = await fakeData(0, true);
    let record = fakeData.find((record) => record.id === recordId) || {};

    for (let field in edits) {
      record[field] = edits[field];
    }

    return Promise.resolve({
      data: record,
    });
  }*/

  return axios.patch(
    `${urlBase}/api/dev/bagging/baggingreports/${recordId}`,
    edits, {
      params: {
        format: "json",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const editBaggingReports = async (config) => {
  let {
    token,
    reports,
    fromFake,
    edits
  } = config;

  if (fromFake) {
    return Promise.all(
      reports.map((report) => {
        return editBaggingReport({
          token,
          recordId: report.id,
          fromFake,
          edits,
        });
      })
    );
  }

  return axios.all(
    reports.map((report) => {
      return editBaggingReport({
        token,
        recordId: report.id,
        fromFake,
        edits,
      });
    })
  );
};

export default fetchBaggingReports;