import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const Crops = () => {
    let [dest, setDest] = useState('');
    let location = useLocation();

    if (dest) {
        return <Redirect to={{
            pathname: dest,
            state: { from: location }
        }} />;
    }

    return (
        <div className="columns">
            <div className="column is-4">
                <div onClick={() => setDest('/corn')} className="box is-flex justify-center min-h-300 pointer bg-corn">
                    <h3 className="title is-3">
                        Corn
                    </h3>
                </div>
            </div>
            <div className="column is-4">
                <div onClick={() => setDest('/soybeans')} className="box is-flex justify-center min-h-300 pointer bg-soy">
                    <h3 className="title is-3">
                        Soybeans
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default Crops;