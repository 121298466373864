import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.scss';
import App from './App';
import { Auth0Provider } from './auth0_spa';
import config from './auth_config.json';
import history from './utils/history';

Sentry.init({
  dsn: "https://914db80d03844df6ab2e8dff183d997d@o345074.ingest.sentry.io/5255617"
});

const onRedirectCallback = appState => {
  let { pathname } = window.location;

  // get rid of query string w/o refresh
  if (pathname === '/') {
    window.history.replaceState(appState || {}, window.document.title, '/')
  }

  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={config.audience}
  >
    <App />
  </Auth0Provider>, 
  document.getElementById('root'));
