import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../auth0_spa";
import Spinner from "../spinner";
import Contract from "../contract";
import SearchInput from "../search-input";
import DatePicker from "../datepicker";
import { fetchBaggingReports } from '../../utils/api';
import Breadcrumb from "../breadcrumb";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarWeek, faStickyNote, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { toast } from '@seedbin/seedbin-ui';

const Contracts = ({match}) => {
  let { params = {} } = match;
  let { loading, getTokenSilently } = useAuth0();
  let [ reports, setReports ] = useState([]);
  let [ filter, setFilter ] = useState("");
  let [ reportsById, setReportsById ] = useState([]);
  let [ fetching, setFetching ] = useState(false);
  let thisYear = new Date().getMonth() < 7 ? new Date().getFullYear() - 1 : new Date().getFullYear();
  let processYears = [thisYear]; //[];

  for (let i = 1; i < 10; i++) {
    processYears.push(thisYear - i);
  }

  let [ processYear, setProcessYear ] = useState(processYears[0]);
  let cornReportTypes = ['', 'bagging', 'rebag', 'retag', 'overtreat', 'blend'];
  let soybeanReportTypes = ['', 'production', 'custom bag', 'rebag', 'retag', 'overtreat'];
  let [ reportType, setReportType ] = useState("");
  let [ startDate, setStartDate ] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()));
  let [ endDate, setEndDate ] = useState(null);
  let [ resetFormKey, updateFormKey ] = useState(0);
  let [ facilityFilter, setFacilityFilter ] = useState('');
  let [ facilityFilters, setFacilityFilters ] = useState([]);
  let company = match.params.company;

  let onSearch = (e) => {
    setFilter(e.target.value);
  };
  
  useEffect(() => {

    setFacilityFilters(
      reports.reduce((p,c) => {
        if (!p.includes(c.report_location_code)) {
          p.push(c.report_location_code);
        }

        return p;
      }, [])
    );

    setReportsById(
      reports
        .filter(report => {
          if (!filter) return true;
          return Object.values(report)
            .some(fieldVal => {
              let regEx = new RegExp(filter);

              return regEx.test(String(fieldVal));
            });
        })
        .filter(report => {
          if (!facilityFilter) return true;
          return  report.report_location_code === facilityFilter;
        })
        .reduce((p, c) => {
          if (!p.includes(c.report_id)) p.push(c.report_id);
          return p;
        }, [])
        .map((reportId) => {
          return {
            reportId,
            reports: reports.filter(
              (report) => report.report_id === reportId
            ),
          };
        })
    )
  }, [reports, filter, facilityFilter])

  // Fetch reports...
  useEffect(() => {
    let formattedStart = startDate instanceof Date ?
      `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}` :
      null;
    let formattedEnd = endDate instanceof Date ?
      `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}` :
      null;

    // Exit function early if poorly formatted start or end.
    if ((formattedStart && isNaN(startDate.getTime())) || (formattedEnd && isNaN(endDate.getTime()))) {
      return console.warn('poorly formatted start or end date');
    }

    const getData = async () => {
      let token = await getTokenSilently();
      let { crop } = params;

      // make api call w/token
      let res = {};
      try {
        res = await fetchBaggingReports({token, crop, processYear, reportType, startDate: formattedStart, endDate: formattedEnd, company});

        // On failure let user know, and don't set data
        if (res.status !== 200) {
          console.log({res});
          return toast.error(`${res.status} | ${res.statusText}`);
        }
      } catch (e) {
        console.error(e.message);
      }

      setReports(res.data);
    };

    if (!loading) {
      setFetching(true);
      getData()
          .then(() => setFetching(false));
    }
  }, [loading, getTokenSilently, params.crop, processYear, reportType, startDate, endDate, company]);

  let resetFilters = () => {
    setFilter('');
    setProcessYear(thisYear);
    setReportType('');
    setStartDate(null);
    setEndDate(null);
    updateFormKey(key => ++key)
  }

  if (loading) return <Spinner />;

  return (
    <div className="is-relative">
      <div style={{position: 'sticky', top: 0, zIndex: 20}} className="has-background-grey-lighter">

        <div className="mb-3">
          <Breadcrumb className="m-0" capitalize rootLinkName="Crops">

            <button className="button is-small" onClick={resetFilters}>
            <span className="icon has-text-danger">
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
              <span>Clear Search</span>
            </button>

          </Breadcrumb>
        </div>
        <div className="table-search box has-background-light">
          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>Process Year</label>
            <div className="control has-icons-left">
              <div className="select is-success">
                <select onChange={(e) => {setProcessYear(e.target.value*1)}} className="has-background-light" value={processYear}>
                  {processYears.map((blank, i) => {
                    return <option key={`processyear-${i}`}>
                      {processYears[i]}
                    </option>;
                  })}
                </select>
              </div>
              <div className="icon is-small is-left has-text-grey">
                <FontAwesomeIcon icon={faCalendarWeek} />
              </div>
            </div>
          </div>

          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>Report Type</label>
            <div className="control has-icons-left">
              <div className="select is-success">
                <select onChange={(e) => {setReportType(e.target.value)}} className="has-background-light" value={reportType}>
                  {params.crop === 'corn' ?
                    cornReportTypes.map((blank, i) => {
                      return <option key={`reporttype-${i}`}>
                        {cornReportTypes[i]}
                      </option>;
                    })
                    :
                    soybeanReportTypes.map((blank, i) => {
                      return <option key={`reporttype-${i}`}>
                        {soybeanReportTypes[i]}
                      </option>;
                    })
                  }
                </select>
              </div>
              <div className="icon is-small is-left has-text-grey">
                <FontAwesomeIcon icon={faStickyNote} />
              </div>
            </div>
          </div>

          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>Facility/Location</label>
            <div className="control has-icons-left">
              <div className="select is-success">
                <select onChange={(e) => {setFacilityFilter(e.target.value)}} className="has-background-light" value={facilityFilter}>
                  <option value={''}>All</option>
                  {
                    facilityFilters.map(fac => <option key={fac} value={fac}>{fac}</option>)
                  }
                </select>
              </div>
              <div className="icon is-small is-left has-text-grey">
                <FontAwesomeIcon icon={faStickyNote} />
              </div>
            </div>
          </div>

          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>Start Date</label>

            <div className="control has-icons-left" style={{height:'2.5em', paddingTop:'8px'}}>
              <DatePicker
                id="filter-start-date"
                key={resetFormKey}
                label=""
                onChange={(e) => {setStartDate(e)}}
                value={startDate}
              />
            </div>
          </div>

          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>End Date</label>

            <div className="control has-icons-left" style={{height:'2.5em', paddingTop:'8px'}}>
              <DatePicker
                id="filter-end-date"
                key={resetFormKey}
                label=""
                onChange={(e) => {setEndDate(e)}}
                value={endDate}
              />
            </div>
          </div>
          <div className="field m-0">
            <label style={{fontSize:'0.7em'}}>Search</label>

            <SearchInput key={resetFormKey} inputBg="has-background-light" onChange={onSearch} style={{height:'2.5em'}} />
          </div>
        </div>

      </div>

      {!reportsById.length && !fetching && <div className="columns is-centered"><div className="column is-4"><div className="notification is-danger is-light"><h1 className="title is-1 has-text-centered">No Data For Process Year {processYear}</h1></div></div></div> }
      {fetching ? <Spinner /> : reportsById.map((report, i) => (
        <Contract
          key={`contract-item-${i}`}
          number={report.reportId}
          reports={report.reports}
          company={company}
          match={match}
        />
      ))}
    </div>
  );
};

export default Contracts;