import React from "react";
import {
  faMapMarkerAlt,
  faSeedling,
  faCalendarDay,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  getBaggingReportLocations,
  getBaggingReportVarieties,
  getBaggingReportDates,
} from "../../utils/array";
import { FancyLi } from "@seedbin/seedbin-ui";

const Contract = ({ number, reports = [], match = {}, company }) => {
  let { params = {} } = match;
  let locations = getBaggingReportLocations(reports, params.crop);
  let totalLineItems = reports.length;
  let varieties = getBaggingReportVarieties(reports);
  let dateString = getBaggingReportDates(reports, params.crop);

  const iconList = [
    {
      icon: faMapMarkerAlt,
      title: [locations],
    },
    {
      icon: faSeedling,
      title: [params.crop],
    },
  ];
  const midIconList = [
    {
      icon: faCalendarDay,
      title: [dateString],
    },
  ];
  
  /*midTitleStatus={
        needsApproval ? (
          <span className="tag is-warning is-light has-text-weight-normal">
            {needsApproval} unapproved
          </span>
        ) : (
          <span className="tag is-success is-light has-text-weight-normal">
            {totalLineItems} approved
          </span>
        )
      }*/
  
  return (
        <FancyLi
            className={`bg-${params.crop}-right`}
            title={number}
            subtitle={varieties.join(", ")}
            iconList={iconList}
            midTitle={<span>{totalLineItems} Line Items</span>}
            midIconList={midIconList}
            destComponent={
              <Link className={'has-text-grey'} to={{
                'pathname': `/${params.crop}/${company}/${number}`,
                state: {
                  reports
                }
              }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            }
        />
  );
};

export default Contract;
