import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay, faDolly, faHashtag, faMapMarkerAlt, faCalendar, faSeedling } from '@fortawesome/free-solid-svg-icons'; //faTools

const BaggingReportSummary = ({ contractNumber, tables, dateString, locations, cropYear, relativeMaturity }) => {
  return (
    <div className={`panel has-background-white is-primary`}>
      <div className="panel-heading has-text-centered">{contractNumber}</div>
      <div className="panel-block is-block">

          <div className="columns">

            <div className="column">
              <article className="media">
                <figure className="media-left has-text-primary">
                  <FontAwesomeIcon icon={faHashtag} fixedWidth />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <p><strong> Bagging Report No.</strong><br/>
                      {tables.length ? tables[0].reportId : ''}</p>
                  </div>
                </div>
              </article>
            </div>

            <div className="column">
              <article className="media">
                <figure className="media-left has-text-primary">
                  <FontAwesomeIcon icon={faCalendar} fixedWidth />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <p><strong> Crop Year</strong><br/>
                      {cropYear}</p>
                  </div>
                </div>
              </article>
            </div>

            <div className="column">
              <article className="media">
                <figure className="media-left has-text-primary">
                  <FontAwesomeIcon icon={faCalendarDay} fixedWidth />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <p><strong> Date</strong> <br/>
                      {dateString}</p>
                  </div>
                </div>
              </article>
            </div>

            <div className="column">
              <article className="media">
                <figure className="media-left has-text-primary"><FontAwesomeIcon icon={faDolly} fixedWidth /></figure>
                <div className="media-content">
                  <div className="content">
                    <p><strong> Activity</strong> <br/>
                      Bagging</p>
                  </div>
                </div>
              </article>
            </div>

            <div className="column">
              <article className="media">
                <figure className="media-left has-text-primary"><FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth /></figure>
                <div className="media-content">
                  <div className="content">
                    <p><strong> Location</strong> <br/>
                      {locations}</p>
                  </div>
                </div>
              </article>
            </div>

            {
              relativeMaturity &&
              <div className="column">
                <article className="media">
                  <figure className="media-left has-text-primary">
                    <FontAwesomeIcon icon={faSeedling} fixedWidth/>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p><strong> Relative Maturity</strong><br/>
                        {relativeMaturity}</p>
                    </div>
                  </div>
                </article>
              </div>
            }

          </div>

        <div className="w-100">
          {
            /*relativeMaturity &&
            <article className="media">
              <figure className="media-left has-text-primary">
                <FontAwesomeIcon icon={faSeedling} fixedWidth/>
              </figure>
              <div className="media-content">
                <div className="content">
                  <p><strong> Relative Maturity</strong><br/>
                    {relativeMaturity}</p>
                </div>
              </div>
            </article>*/
          }
        </div>
      </div>

    </div>
  );
};

export default BaggingReportSummary;
